import { styled } from "@mui/material";


export const LoaderWrapper = styled('div')`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1200;
    background: rgba(255, 255, 255, 0.603);

    &.active {
        display: flex;
    }

    .push_pop {
        --duration: .85;
        height: 100px;
        width: 100px;
        position: relative;
        overflow: hidden;

        span {
            &:first-child {
                height: 20px;
                width: 20px;
                position: absolute;
                animation: push-pop-slide calc(var(--duration) * 1s) infinite alternate ease-in-out;
                transform: translate(0, -100%);
                top: 100%;
                left: 0;

                &:after {
                    animation: push-pop-flip calc(var(--duration) * 1s) infinite alternate ease-in-out;
                    background: #FC6736;
                    content: '';
                    height: 100%;
                    position: absolute;
                    width: 100%;
                }
            }

            &:last-child {
                background: #02C0FF;
                height: 30px;
                left: 50%;
                position: absolute;
                top: 100%;
                transform: translate(-50%, -100%);
                width: 20px;
            }
        }

        &:after,
        &:before {
            animation: push-pop-pushed calc(var(--duration) * 1s) alternate infinite ease;
            background: #FC6736;
            bottom: 0;
            content: '';
            height: 40px;
            position: absolute;
            width: 20px;
        }

        &:before {
            animation: push-pop-pushed calc(var(--duration) * 1s) alternate-reverse infinite ease;
            left: 0;
        }

        &:after {
            right: 0;
        }

        @keyframes push-pop-pushed {
            0%, 72.5% {
                transform: translate(0, 0);
            }
            100% {
                transform: translate(0, 100%);
            }
        }

        @keyframes push-pop-flip {
            0% {
                transform: translate(0, 0) rotate(0deg);
            }
            50% {
                transform: translate(0, -80px) rotate(90deg);
            }
            100% {
                transform: translate(0, 0) rotate(180deg);
            }
        }

        @keyframes push-pop-slide {
            to {
                transform: translate(0, -100%) translate(80px, 0);
            }
        }
    }

    .text {
        position: relative;
        margin-top: 25px;
        display: flex;
        align-items: center;

        h4 {
            position: relative;
            font-size: 18px;
            line-height: 1;
            font-weight: 600;
        }

        svg {
            position: relative;
            margin-left: 3px;
            width: 50px;
            height: 35px;
        }
    }
`;