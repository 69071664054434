import { useContext, useEffect, useState } from "react";
import { TicketListWrapper } from "../Styles/Booking-Style";
import { DataContext } from "../Layouts/Data-Context";
import axios from "axios";
import { Fetch_Cancelled_Tickets_URL } from "../API/Api";



function CancelledBookings() {

    const {setBookingId, setCurrTicket, setCanTicket, setShowDetails} = useContext(DataContext);
    const [tickets, setTickets] = useState([]);
    const [ticketCount, setTicketCount] = useState('');

    useEffect(() => {
        let loginStatus = localStorage.getItem("Login");
        if (loginStatus == "true") {
            let user = JSON.parse(localStorage.getItem("UserData"));
            axios.get(`${Fetch_Cancelled_Tickets_URL}?name=${user.user}`)
            .then(res => {
                // console.log(res);
                setTicketCount(res.data.count);
                setTickets(res.data.tickets);
            })
            .catch(err => {
                // console.log(err);
                setTickets([]);
            })
        }
    }, []);

    const handleShowDetails = (id) => {
        setBookingId(id);
        setCurrTicket(false);
        setCanTicket(true);
        setShowDetails(true);
    };

    return(
        <>
            <TicketListWrapper>
                {
                    ticketCount > 0 ? 
                    tickets && tickets.map((ticket, index) => 
                    <div className="ticket_box" key={index} onClick={() => handleShowDetails(ticket.booking_id)}>
                        <div className="box_inner">
                            <div className="ticket cancelled">
                                <div className="left_part">
                                    <div class="cuts">
                                        <div class="cut"></div>
                                        <div class="cut"></div>
                                        <div class="cut"></div>
                                        <div class="cut"></div>
                                        <div class="cut"></div>
                                        <div class="cut"></div>
                                        <div class="cut"></div>
                                        <div class="cut"></div>
                                        <div class="cut"></div>
                                        <div class="cut"></div>
                                    </div>

                                    <div class="content">
                                        <div class="content_inner">
                                            <h4>{ticket.movie}</h4>
                                            <div className="date_time">
                                                <p>{ticket.date}</p>
                                                <span><i className="fa-solid fa-circle"></i></span>
                                                <p>{ticket.time}</p>
                                            </div>
                                            <div className="section">
                                                <span>Section :</span>
                                                <p>{ticket.class}</p>
                                            </div>
                                            <div className="seats">
                                                <span>Seats :</span>
                                                <p>{ticket.seats}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="right_part">
                                    <div class="cuts">
                                        <div class="cut"></div>
                                        <div class="cut"></div>
                                        <div class="cut"></div>
                                        <div class="cut"></div>
                                        <div class="cut"></div>
                                        <div class="cut"></div>
                                        <div class="cut"></div>
                                        <div class="cut"></div>
                                        <div class="cut"></div>
                                        <div class="cut"></div>
                                    </div>
                                    <div class="number"><p>{ticket.booking_id}</p></div>
                                </div>

                                <div class="circles">
                                    <div class="circle"></div>
                                    <div class="circle"></div>
                                </div>
                            </div>
                            <div className="cancel_tag">
                                <img src="../images/cancel-tag.png" alt="cancel" />
                            </div>
                        </div>
                    </div>)
                    :
                    <div className="empty_message">
                        <p>No cancelled bookings.</p>
                    </div>
                }
            </TicketListWrapper>
        </>
    );
}


export default CancelledBookings;