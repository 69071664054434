import { useContext, useEffect, useRef, useState } from "react";
import { CancelConfirmModalBox } from "../Styles/Modal-Style";
import axios from "axios";
import { Cancel_Ticket__URL, Fetch_Tickets_Details_URL } from "../API/Api";
import { DataContext } from "../Layouts/Data-Context";



function CancelConfirmationModal({bookingId, cancelModal, setCancelModal, setShowDetails, setReload}) {

    const {setRefundModal} = useContext(DataContext);
    const [hoursLeft, setHoursLeft] = useState('');
    const [refundAmount, setRefundAmount] = useState('');
    const popupRef = useRef(null);


    useEffect(() => {
        let user = JSON.parse(localStorage.getItem("UserData"));
        axios.get(`${Fetch_Tickets_Details_URL}?name=${user.user}&&bookingId=${bookingId}`)
        .then(res => {
            // console.log(res.data.ticket);
            const ticketPrice = res.data.ticket.ticket_price;
            const showTime = res.data.ticket.time;
            const showDate = res.data.ticket.original_date;

            const targetDateTime = new Date(`${showDate} ${showTime}`);
            const currentDateTime = new Date();

            const timeDifference = targetDateTime - currentDateTime;

            const hours = (timeDifference / (1000 * 60 * 60)).toFixed(2); // 2 decimal places
            setHoursLeft(hours);

            if(hours >= 6) {
                const amount = ticketPrice;
                setRefundAmount(amount);
            } else if(hours < 6 && hours >= 3) {
                const amount = (ticketPrice / 2).toFixed(2);
                setRefundAmount(amount);
            }
        })
        .catch(err => {
            console.log(err);
        })
    }, [bookingId]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setCancelModal(false);
            }
        }

        if (cancelModal) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [cancelModal, setCancelModal]);

    function closeCancelModal() {
        setCancelModal(false);
    }

    function openReturnPolicy() {
        setRefundModal(true);
        setCancelModal(false);
        setShowDetails(false);
    }

    const handleCancelTicket = () => {
        const inputs = {
            bookingId: bookingId,
            refundAmount: refundAmount
        };

        axios.post(Cancel_Ticket__URL, inputs, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res => {
            console.log(res);
            if(res.data.status === "200"){
                setReload(true);
            }
        })
        .catch(err => {
            console.log(err);
        })

        setShowDetails(false);
        setCancelModal(false);
    };

    return(
        <>
            <CancelConfirmModalBox className={cancelModal ? 'active' : ''}>
                <div className={`popup_box ${cancelModal ? 'active' : ''}`} ref={popupRef}>
                    <div className="popup_inner">
                        <div className="icon">
                            <img src="/images/cancel-icon.svg" alt="cancel" />
                        </div>
                        <div className="content">
                            {
                                hoursLeft < 3 ? 
                                <p>If you cancel this booking, you'll not get any refund.</p>
                                :
                                <p>If you cancel this booking, you'll get <span><i className="fa-solid fa-indian-rupee-sign"></i>{refundAmount}/- (ticket price)</span> in refund.</p>
                            }
                        </div>
                        <div className="redirect_box">
                            <p>For further details, please go through our <a onClick={openReturnPolicy}>refund policy</a>.</p>
                        </div>
                        <div className="popup_btns">
                            <button onClick={closeCancelModal}><span>Close</span></button>
                            <button onClick={handleCancelTicket}><span>Proceed</span></button>
                        </div>
                    </div>
                </div>
            </CancelConfirmModalBox>
        </>
    );
}


export default CancelConfirmationModal;