import { useEffect, useState } from "react";
import { MovieFilterModalBox } from "../Styles/Modal-Style";
import axios from "axios";
import { Fetch_Filtered_Shows_URL } from "../API/Api";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify';


function MovieFilter({filterModal, setFilterModal, selectedCity, setMovies, setReload}) {

    const languages = ['Hindi','English','Bengali','Tamil','Telugu','Kannada','Malayalam','Marathi','Gujrati','Punjabi','Konkani','Tulu'];
    const genres = ['Action','Adventure','Biography','Comedy','Musical','Drama','Fantasy','Animation','Documentary','Political','Horror','Romance','Sci-Fi','Sports','Mystery','Thriller'];

    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [selectedGenres, setSelectedGenres] = useState([]);


    useEffect(() => {
        setSelectedLanguages([]);
        setSelectedGenres([]);
    }, [selectedCity]);

    function handleLanguageChange(event) {
        const { checked, value } = event.target;
        setSelectedLanguages((prev) =>
            checked ? [...prev, value] : prev.filter((lang) => lang !== value)
        );
    }

    function handleGenreChange(event) {
        const { checked, value } = event.target;
        setSelectedGenres((prev) =>
            checked ? [...prev, value] : prev.filter((genre) => genre !== value)
        );
    }

    function handleApply() {
        const inputs = {
            location: selectedCity,
            lanuages: selectedLanguages.join(', '),
            genres: selectedGenres.join(', ')
        }
        axios.post(Fetch_Filtered_Shows_URL, inputs, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res => {
            console.log(res);
            toast.success('Filters applied !!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            setMovies(res.data.week_shows);
        })
        .catch(err => {
            console.log(err);
            setMovies([]);
        })
    }

    function handleReset() {
        setSelectedLanguages([]);
        setSelectedGenres([]);
        setReload(true);
    }

    function modalClose() {
        setFilterModal(false);
    }

    return(
        <>
            <MovieFilterModalBox className={filterModal ? 'active' : ''}>
                <div className="modal_inner">
                    <div className="modal_head">
                        <h3>Filters</h3>
                        <a onClick={modalClose}><i class="fa-solid fa-arrow-left-long"></i></a>
                    </div>
                    <div className="modal_body">
                        <div className="body_content">
                            <div className="item_box">
                                <div className="box_head">
                                    <h5>Languages</h5>
                                </div>
                                <div className="option_box_sec">
                                    {
                                        languages.map((language, i) => 
                                            <a key={i}>
                                                <input type="checkbox" id={`language-${i}`} value={language} checked={selectedLanguages.includes(language)} onChange={handleLanguageChange} />
                                                <label htmlFor={`language-${i}`}>{language}</label>
                                            </a>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="item_box">
                                <div className="box_head">
                                    <h5>Genres</h5>
                                </div>
                                <div className="option_box_sec">
                                    {
                                        genres.map((genre, i) => 
                                            <a key={i}>
                                                <input type="checkbox" id={`genre-${i}`} value={genre} checked={selectedGenres.includes(genre)} onChange={handleGenreChange} />
                                                <label htmlFor={`genre-${i}`}>{genre}</label>
                                            </a>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="modal_btns">
                            <button onClick={handleReset}>Reset</button>
                            <button onClick={handleApply}>Apply</button>
                        </div>
                    </div>
                </div>
            </MovieFilterModalBox>
        </>
    );
}


export default MovieFilter;