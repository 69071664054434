import { useEffect, useRef, useState } from "react";
import OTPInput from "react-otp-input";
import { OTPVerificationModalBox } from "../Styles/Modal-Style";
import axios from "axios";
import { User_Details_URL, Verify_OTP_URL } from "../API/Api";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify';


function PasswordChangeOTPVerification({passwordOtpModal, setPasswordOtpModal, otpEmail, setPasswordChangeModal}) {

    const [otp, setOtp] = useState('');
    const [timer, setTimer] = useState(120);
    const [isComplete, setIsComplete] = useState(false);
    const [email, setEmail] = useState('');
    const modalBoxRef = useRef();

    useEffect(() => {
        const [localPart, domain] = otpEmail.split("@");
        const visibleStart = localPart.slice(0, 3);
        const visibleEnd = localPart.slice(-3);
        const obfuscatedLocal = `${visibleStart}******${visibleEnd}`;
        setEmail(`${obfuscatedLocal}@${domain}`);
    }, [passwordOtpModal]);

    useEffect(() => {
        if (timer > 0) {
            const interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [timer, passwordOtpModal]);

    useEffect(() => {
        if (passwordOtpModal) {
            setTimer(120);
        }
    }, [passwordOtpModal]);

    useEffect(() => {
        if(otp && otp.length === 6){
            return setIsComplete(true);
        }
        return setIsComplete(false);
    }, [otp]);

    const handleResendOTP = () => {
        setTimer(120);
        console.log("Resend OTP clicked");
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalBoxRef.current && !modalBoxRef.current.contains(event.target)) {
                setPasswordOtpModal(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setPasswordOtpModal]);

    function modalClose() {
        setPasswordOtpModal(false);
    }

    const handleVerifyOTP = () => {
        
        const inputs = {
            email: otpEmail,
            otp: otp
        };

        axios.post(Verify_OTP_URL, inputs, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res => {
            // console.log(res);
            toast.success('OTP Verified !!!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            setOtp('');
            setPasswordOtpModal(false);
            setPasswordChangeModal(true);
        })
        .catch(err => {
            // console.log(err);
            toast.error('Wrong OTP !!!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        })
    }

    return(
        <>
            <OTPVerificationModalBox className={passwordOtpModal ? 'active' : ''}>
                <div ref={modalBoxRef} className={`modal_box ${passwordOtpModal ? 'active' : ''}`}>
                    <div className="box_inner">
                        <div className="icon">
                            <img src="/images/Otp-Verification-icon.svg" alt="icon" />
                        </div>
                        <div className="heading">
                            <p>OTP has been send to <span>{email}</span></p>
                        </div>
                        <div className="otp_input_sec">
                            <OTPInput 
                                value={otp}
                                onChange={setOtp}
                                numInputs={6} 
                                renderInput={(props) => <input {...props} inputMode="numeric" pattern="[0-9]*" required />}
                            />
                        </div>
                        <div className="resend_sec">
                            {timer > 0 ? 
                                <p>Resend OTP in {timer}s</p>
                                : 
                                <p className="resend_now" onClick={handleResendOTP}>
                                    Resend Now
                                </p>
                            }
                        </div>
                        <div className="btn_sec">
                            <button onClick={modalClose}>Cancel</button>
                            <button className={isComplete ? 'active' : ''} onClick={handleVerifyOTP}>Verify</button>
                        </div>
                    </div>
                </div>
            </OTPVerificationModalBox>
        </>
    );
}


export default PasswordChangeOTPVerification;