import { styled } from "@mui/material";

export const ContactWrapper = styled('div')`
    position: relative;
    width: 100%;
    padding: 100px;
    z-index: 15;

    .page_inner {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-top: 50px;
        padding-bottom: 80px;

        .page_head {
            position: relative;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            h2 {
                position: relative;
                display: flex;
                align-items: center;

                span:nth-of-type(1) {
                    position: relative;
                    font-size: 55px;
                    font-weight: 700;
                    font-family: arista-pro-trial-bold;
                    color: #000;
                    z-index: 1;
                }

                span:nth-of-type(2) {
                    position: relative;
                    margin-left: 20px;
                    font-size: 55px;
                    font-family: arista-pro-alt-bold;
                    color: #FC6736;
                }

                svg {
                    position: absolute;
                    left: -30px;
                    width: 250px;
                    overflow: inherit;
                }

                @media (max-width: 1399px) {
                    span:nth-of-type(1) {
                        font-size: 45px;
                    }

                    span:nth-of-type(2) {
                        font-size: 45px;
                    }

                    svg {
                        left: -50px;
                        width: 250px;
                    }
                }

                @media (max-width: 575px) {
                    span:nth-of-type(1) {
                        font-size: 35px;
                    }

                    span:nth-of-type(2) {
                        font-size: 35px;
                    }

                    svg {
                        left: -50px;
                        width: 200px;
                    }
                }

                @media (max-width: 450px) {
                    span:nth-of-type(1) {
                        font-size: 30px;
                    }

                    span:nth-of-type(2) {
                        font-size: 30px;
                    }

                    svg {
                        left: -20px;
                        width: 180px;
                    }
                }
            }
        }

        .contact_sec {
            position: relative;
            width: 100%;
            margin-top: 60px;
            display: flex;
            flex-direction: column;

            .sec_heading {
                position: relative;
                width: 100%;
                display: flex;
                justify-content: center;

                h3 {
                    position: relative;
                    font-family: 'Lemonada', cursive;
                    font-size: 27px;
                    font-weight: 700;
                    color: #444;
                    text-align: center;

                    @media (max-width: 768px) {
                        font-size: 24px;
                    }

                    @media (max-width: 575px) {
                        font-size: 20px;
                    }
                }
            }

            .contact_info_sec {
                position: relative;
                width: 100%;
                margin-top: 20px;
                display: flex;
                justify-content: center;

                .info_box {
                    position: relative;
                    display: flex;
                    align-items: center;

                    &:first-child {
                        margin-right: 50px;
                    }

                    i {
                        position: relative;
                        font-size: 16px;
                        color: #FC6736;
                        margin-right: 5px;
                    }

                    p {
                        position: relative;
                        font-size: 17px;
                        font-weight: 800;
                        font-family: "SUSE", sans-serif;
                    }

                    span {
                        position: relative;
                        font-size: 17px;
                        color: #555;
                        margin-left: 6px;
                        font-weight: 500;
                    }

                    @media (max-width: 768px) {
                        p, span {
                            font-size: 15px;
                        }
                    }
                }

                @media (max-width: 768px) {
                    flex-direction: column;
                    align-items: center;

                    .info_box {
                        &:first-child {
                            margin-right: 0;
                            margin-bottom: 8px;
                        }
                    }
                }
            }

            .address_sec {
                position: relative;
                width: 100%;
                margin-top: 30px;
                display: flex;
                justify-content: center;

                .address_box {
                    position: relative;
                    width: 325px;
                    background: #FFF;
                    padding: 25px 20px 25px 20px;
                    border-radius: 0 0 6px 6px;
                    box-shadow: 6px 8px 15px rgba(0,0,0,0.3),
                                -3px -3px 6px rgba(97, 97, 97, 0.2);
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 4px;
                    }

                    &:first-child::before {
                        background: #3FE44F;
                    }

                    &:last-child::before {
                        background: #02C0FF;
                    }

                    &:last-child {
                        margin-left: 50px;
                    }

                    h5 {
                        position: relative;
                        width: max-content;
                        text-align: center;
                        font-family: "SUSE", sans-serif;
                        font-size: 19px;
                        display: flex;
                        justify-content: center;

                        &::after {
                            content: '';
                            position: absolute;
                            bottom: -8px;
                            width: 55%;
                            height: 3px;
                            background: #FC6736;
                            border-radius: 25px;
                        }
                    }

                    p {
                        position: relative;
                        margin-top: 25px;
                        font-size: 15px;
                        line-height: 1.5;
                        color: #333;
                    }
                }

                @media (max-width: 678px) {
                    flex-direction: column;

                    .address_box {
                        width: 100%;

                        &:last-child {
                            margin-left: 0;
                            margin-top: 35px;
                        }
                    }
                }
            }

            .gap {
                position: relative;
                margin: 50px 0;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                span {
                    position: relative;
                    width: 300px;
                    height: 2px;
                    background: linear-gradient(to right, #acacac, #FFF);

                    &:first-child {
                        transform: rotateZ(-180deg);
                    }
                }

                h4 {
                    position: relative;
                    font-family: 'Lemonada', cursive;
                    font-size: 20px;
                    margin: 0 25px;
                }

                @media (max-width: 575px) {
                    margin: 30px 0;
                }
            }

            .contact_form_sec {
                position: relative;
                width: 100%;
                display: flex;
                justify-content: center;

                form {
                    position: relative;
                    width: 700px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .form_box {
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 20px;

                        &.halfWidth {
                            width: 48.6%;

                            @media (max-width: 575px) {
                                width: 100%;
                            }
                        }

                        &.fullWidth {
                            width: 100%;
                        }

                        input {
                            position: relative;
                            width: 100%;
                            height: 45px;
                            padding: 5px 20px;
                            border-radius: 5px;
                            outline: none;
                            border: 1px solid rgb(180, 180, 180);
                            font-size: 15px;
                            color: #333;
                            transition: all 0.4s ease-in-out;

                            &:focus,
                            &:valid {
                                border: 1px solid #FC6736;
                                transition: all 0.4s ease-in-out;
                            }
                        }

                        textarea {
                            position: relative;
                            width: 100%;
                            height: 150px;
                            padding: 15px 20px;
                            border-radius: 5px;
                            outline: none;
                            resize: none;
                            border: 1px solid rgb(180, 180, 180);
                            font-size: 15px;
                            color: #333;
                            transition: all 0.4s ease-in-out;

                            &:focus,
                            &:valid {
                                border: 1px solid #FC6736;
                                transition: all 0.4s ease-in-out;
                            }
                        }

                        span {
                            position: absolute;
                            top: 22.5px;
                            left: 15px;
                            padding: 0 5px;
                            display: flex;
                            align-items: center;
                            font-family: "SUSE", sans-serif;
                            font-size: 17px;
                            line-height: 1;
                            background: #FFF;
                            font-weight: 500;
                            color: rgb(149, 149, 149);
                            pointer-events: none;
                            transform: translateY(-50%);
                            transition: all 0.4s ease-in-out;

                            @media (max-width: 575px) {
                                font-size: 15px;
                            }
                        }

                        input:focus ~ span,
                        input:valid ~ span,
                        textarea:focus ~ span,
                        textarea:valid ~ span {
                            top: 0;
                            font-size: 12px;
                            color: #02C0FF;
                            transition: all 0.4s ease-in-out;
                        }
                    }

                    .form_btn {
                        position: relative;
                        margin-top: 10px;
                        width: 100%;

                        button {
                            position: relative;
                            height: 50px;
                            padding: 5px 40px;
                            cursor: pointer;
                            border: none;
                            background: #02C0FF;
                            font-size: 16px;
                            font-weight: 500;
                            display: flex;
                            align-items: center;
                            color: #FFF;
                            border-radius: 5px;
                            transition: all 0.5s;

                            &.disable {
                                pointer-events: none;
                                opacity: 0.3;
                            }

                            &.active {
                                pointer-events: none;
                                opacity: 0.5;
                            }

                            i {
                                position: relative;
                                margin-left: 10px;
                            }

                            svg {
                                position: relative;          
                                width: 50px;
                                height: 17px;

                                rect {
                                    fill: #fff;
                                }
                            }

                            &:hover {
                                border-radius: 50px;
                            }

                            @media (max-width: 450px) {
                                width: 100%;
                                font-size: 15px;
                            }
                        }
                    }
                }
            }

            @media (max-width: 768px) {
                margin-top: 40px;
            }
        }

        @media (max-width: 991px) {
            padding-bottom: 0;
        }
    }

    @media (max-width: 1250px) {
        padding: 100px 70px;
    }

    @media (max-width: 991px) {
        padding: 100px 50px;
    }

    @media (max-width: 575px) {
        padding: 100px 35px;
    }

    @media (max-width: 450px) {
        padding: 100px 25px;
    }

`;