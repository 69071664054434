import { useEffect, useRef, useState } from "react";
import { ProfileUpdateModalBox } from "../Styles/Modal-Style";
import states from '../Data/State-City.json';
import axios from "axios";
import { Profile_Update_URL, User_Details_URL } from "../API/Api";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify';


function ProfileUpdate({profileUpdateModal, setProfileUpdateModal}) {

    const [cities, setCities] = useState([]);
    const [filteredStates, setFilteredStates] = useState(states);
    const [filteredCities, setFilteredCities] = useState([]);

    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');

    const [showStates, setShowStates] = useState(false);
    const [showCities, setShowCities] = useState(false);

    const [initialDetails, setInitialDetails] = useState({});
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const nameRef = useRef();
    const phoneRef = useRef();
    const emailRef = useRef();
    const stateRef = useRef();
    const cityRef = useRef();
    const modalBoxRef = useRef();

    const fetchUserDetails = () => {
        let loginStatus = localStorage.getItem("Login");

        if (loginStatus == "true") {
            let user = JSON.parse(localStorage.getItem("UserData"));
            axios.get(`${User_Details_URL}?user=${user.user}`)
            .then(res => {
                console.log(res);
                const userDetails = res.data.user;

                if (nameRef.current) nameRef.current.value = userDetails.name || '';
                if (emailRef.current) emailRef.current.value = userDetails.email || '';
                if (phoneRef.current) phoneRef.current.value = userDetails.phone || '';
                if (stateRef.current) stateRef.current.value = userDetails.state || '';
                if (cityRef.current) cityRef.current.value = userDetails.city || '';

                setSelectedState(userDetails.state || '');
                setSelectedCity(userDetails.city || '');

                const selectedStateData = states.find((state) => state.name === userDetails.state);
                if (selectedStateData) {
                    setCities(selectedStateData.cities || []);
                    setFilteredCities(selectedStateData.cities || []);
                }

                setInitialDetails({
                    name: userDetails.name || '',
                    email: userDetails.email || '',
                    phone: userDetails.phone || '',
                    state: userDetails.state || '',
                    city: userDetails.city || '',
                });

                setIsButtonDisabled(true);
            })
            .catch(err => {
                console.error(err);
            });
        }
    };

    useEffect(() => {
        fetchUserDetails();
    }, [profileUpdateModal]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalBoxRef.current && !modalBoxRef.current.contains(event.target)) {
                setProfileUpdateModal(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setProfileUpdateModal]);

    const handleInputChange = () => {
        const currentDetails = {
            name: nameRef.current.value,
            email: emailRef.current.value,
            phone: phoneRef.current.value,
            state: stateRef.current.value,
            city: cityRef.current.value,
        };

        // Check if current details differ from initial details
        const hasChanges = Object.keys(initialDetails).some(
            key => currentDetails[key] !== initialDetails[key]
        );

        setIsButtonDisabled(!hasChanges);
    };


    function handleStateDropdown() {
        setShowStates(!showStates);
        setShowCities(false);
    }

    function handleCityDropdown() {
        setShowCities(!showCities);
        setShowStates(false);
    }

    function handleStateSearch(e) {
        const searchValue = e.target.value.toLowerCase();
        const filtered = states.filter(state => state.name.toLowerCase().includes(searchValue));
        setFilteredStates(filtered);
    }

    function handleCitySearch(e) {
        const searchValue = e.target.value.toLowerCase();
        const filtered = cities.filter(city => city.name.toLowerCase().includes(searchValue));
        setFilteredCities(filtered);
    }

    function modalClose() {
        setProfileUpdateModal(false);
    }

    const handleUpdateProfile = () => {
        const userData = {
            name: nameRef.current.value,
            phone: phoneRef.current.value,
            email: emailRef.current.value,
            state: stateRef.current.value,
            city: cityRef.current.value,
        }

        axios.post(Profile_Update_URL, userData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res => {
            toast.success('Profile updated successfully.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            setProfileUpdateModal(false);
        })
        .catch(err => {
            // console.log(err);
            toast.error('Internal Server Error.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        })
    };

    return(
        <>
            <ProfileUpdateModalBox className={profileUpdateModal ? 'active' : ''}>
                <div ref={modalBoxRef} className={`modal_box ${profileUpdateModal ? 'active' : ''}`}>
                    <div className="box_inner">
                        <div className="icon">
                            <img src="/images/profile-update-icon.svg" alt="icon" />
                        </div>
                        <div className="form_sec">
                            <div className="form_box">
                                <input type="text" ref={nameRef} onChange={handleInputChange} required />
                                <span>Name</span>
                            </div>
                            <div className="form_box">
                                <input type="text" ref={emailRef} onChange={handleInputChange} required />
                                <span>Email</span>
                            </div>
                            <div className="form_box">
                                <input type="text"  ref={phoneRef} onChange={handleInputChange} required />
                                <span>Mobile No.</span>
                            </div>
                            <div className="select_box_sec">
                                <div className="select_box">
                                    <input type="text" name="state" id="state" ref={stateRef} onChange={handleInputChange} required />
                                    <div className="dropdown_btn" onClick={handleStateDropdown}>
                                        <p>{selectedState}</p>
                                        <span>State</span>
                                        <i className={`fa-solid fa-angle-down ${showStates? 'active':''}`}></i>
                                    </div>
                                    <div className={`dropdown ${showStates? 'active':''}`}>
                                        <div className="search_sec">
                                            <div className="sec_inner">
                                                <input type="text" placeholder="Search..." onChange={handleStateSearch} />
                                                <i className="fa-solid fa-magnifying-glass"></i>
                                            </div>
                                        </div>
                                        <ul>
                                            {
                                                filteredStates.map(state => (
                                                    <li onClick={(e) => {
                                                        setSelectedState(state.name)
                                                        setShowStates(false)
                                                        setSelectedCity('')
                                                        document.getElementById('state').value = state.name
                                                        document.getElementById('state').click()
                                                        setCities(states.find(state => state.name === e.target.textContent).cities)
                                                        setFilteredCities(states.find(state => state.name === e.target.textContent).cities)
                                                    }}>{state.name}</li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className="select_box">
                                    <input type="text" name="state" id="city" ref={cityRef} onChange={handleInputChange} required />
                                    <div className="dropdown_btn" onClick={handleCityDropdown}>
                                        <p>{selectedCity}</p>
                                        <span>City</span>
                                        <i className={`fa-solid fa-angle-down ${showCities? 'active':''}`}></i>
                                    </div>
                                    <div className={`dropdown ${showCities? 'active':''}`}>
                                        {selectedState && (
                                            <div className="search_sec">
                                                <div className="sec_inner">
                                                    <input type="text" placeholder="Search..." onChange={handleCitySearch} />
                                                    <i className="fa-solid fa-magnifying-glass"></i>
                                                </div>
                                            </div>
                                        )}
                                        <ul>
                                            { 
                                                filteredCities.map(city => (
                                                    <li onClick={(e) => {
                                                        setSelectedCity(city.name)
                                                        setShowCities(false)
                                                        document.getElementById('city').value = city.name
                                                        document.getElementById('city').click()
                                                    }}>{city.name}</li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="btn_sec">
                            <button onClick={modalClose}>Cancel</button>
                            <button className={isButtonDisabled ? '' : 'active'} onClick={handleUpdateProfile}>Update</button>
                        </div>
                    </div>
                </div>
            </ProfileUpdateModalBox>
        </>
    );
}


export default ProfileUpdate;