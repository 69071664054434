import { useEffect, useState } from "react";
import { ContactWrapper } from "../Styles/Contact-Style";
import axios from "axios";
import { Contact_Us_URL, User_Details_URL } from "../API/Api";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify';



function ContactPage() {

    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [mail, setMail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);


    useEffect(() => {
        let loginStatus = localStorage.getItem("Login");
        if (loginStatus == "true") {
            let user = JSON.parse(localStorage.getItem("UserData"));
            axios.get(`${User_Details_URL}?user=${user.user}`)
            .then(res => {
                // console.log(res);
                setName(res.data.user.name);
                setPhone(res.data.user.phone);
                setMail(res.data.user.email);
            })
            .catch(err => {
                console.error(err);
            });
        }
    }, []);

    useEffect(() => {
        const isValid = name.trim() && phone.trim() && mail.trim() && subject.trim() && message.trim();
        setIsFormValid(isValid);
    }, [name, phone, mail, subject, message]);

    const handleSendMessage = (e) => {
        e.preventDefault();
        setLoading(true);
        const inputs = {
            name: name,
            phone: phone,
            mail: mail,
            subject: subject,
            message: message
        }
        axios.post(Contact_Us_URL, inputs, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res => {
            // console.log(res);
            if(res.data.status == "200"){
                toast.success('Message Sent Successfully !!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                setLoading(false);
                setSubject('');
                setMessage('');
            }
        })
        .catch(err => {
            // console.log(err);
            toast.error('Something Went Wrong !!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            setLoading(false);
            setSubject('');
            setMessage('');
        })
    };

    return(
        <>
            <ContactWrapper>
                <div className="page_inner">
                    <div className="page_head">
                        <h2>
                            <span>Contact</span>
                            <span>Us</span>
                            <svg id="sw-js-blob-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                <defs>
                                    <linearGradient id="sw-gradient" x1="0" x2="1" y1="1" y2="0">
                                        <stop id="stop1" stopColor="#FC6736" offset="0%"></stop>
                                        <stop id="stop2" stopColor="rgba(251, 168, 31, 1)" offset="100%"></stop>
                                    </linearGradient>
                                </defs>
                                <path fill="url(#sw-gradient)" d="M9.9,-15C16.8,-13.2,28.9,-18.5,30.4,-17.1C31.8,-15.8,22.6,-7.9,17.2,-3.1C11.8,1.6,10.2,3.3,9.6,6.1C8.9,8.9,9.3,12.8,7.9,15.5C6.5,18.2,3.2,19.5,-0.2,20C-3.7,20.4,-7.4,19.8,-9.3,17.4C-11.2,15,-11.3,10.8,-13.9,7.6C-16.5,4.4,-21.6,2.2,-22,-0.2C-22.4,-2.6,-18.1,-5.3,-15.1,-7.8C-12.1,-10.4,-10.5,-12.8,-8.2,-17.2C-5.9,-21.5,-2.9,-27.8,-0.7,-26.6C1.5,-25.4,3.1,-16.7,9.9,-15Z" width="100%" height="100%" transform="translate(15 50)" strokeWidth="0"></path>
                            </svg>
                        </h2>
                    </div>
                    <div className="contact_sec">
                        <div className="sec_heading">
                            <h3>Shetty Ticket Counter Pvt. Ltd.</h3>
                        </div>
                        <div className="contact_info_sec">
                            <div className="info_box">
                                <i className="fa-solid fa-phone"></i>
                                <p>Mobile No.:</p>
                                <span>9449618559</span>
                            </div>
                            <div className="info_box">
                                <i className="fa-solid fa-envelope"></i>
                                <p>Email:</p>
                                <span>ticketbayind@gmail.com</span>
                            </div>
                        </div>
                        <div className="address_sec">
                            <div className="address_box">
                                <h5>Registered Address</h5>
                                <p>THE MERIDIAN Luxury Hotel, District Stadium Main Road Kurvangi main road, Dhantaramakki, Chikkamagaluru, Karnataka, PIN: 577101</p>
                            </div>
                            <div className="address_box">
                                <h5>Operational Address</h5>
                                <p>THE MERIDIAN Luxury Hotel, District Stadium Main Road Kurvangi main road, Dhantaramakki, Chikkamagaluru, Karnataka, PIN: 577101</p>
                            </div>
                        </div>
                        <div className="gap">
                            <span></span>
                            <h4>Or</h4>
                            <span></span>
                        </div>
                        <div className="contact_form_sec">
                            <form action="">
                                <div className="form_box fullWidth">
                                    <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
                                    <span>Name</span>
                                </div>
                                <div className="form_box halfWidth">
                                    <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} required />
                                    <span>Contact No.</span>
                                </div>
                                <div className="form_box halfWidth">
                                    <input type="text" value={mail} onChange={(e) => setMail(e.target.value)} required />
                                    <span>Email</span>
                                </div>
                                <div className="form_box fullWidth">
                                    <input type="text" value={subject} onChange={(e) => setSubject(e.target.value)} required />
                                    <span>Subject</span>
                                </div>
                                <div className="form_box fullWidth">
                                    <textarea name="" value={message} onChange={(e) => setMessage(e.target.value)} required></textarea>
                                    <span>Message</span>
                                </div>
                                <div className="form_btn">
                                    <button className={`${isFormValid ? '' : 'disable'} ${loading ? 'active' : ''}`} onClick={handleSendMessage}>
                                        {
                                            loading ? 
                                            <>
                                                Sending <svg width="135" height="60" viewBox="0 0 100 60">
                                                <rect x="0" y="0" width="10" height="60" rx="6"><animate attributeType="CSS" attributeName="height" values="60;20;60;" begin='0s' dur="1s" repeatCount="indefinite" /><animate attributeType="CSS" attributeName="y" begin='0s' values="0;20;0;" dur="1s" repeatCount="indefinite" /></rect>

                                                <rect x="20" y="0" width="10" height="60" rx="6"><animate attributeType="CSS" attributeName="height" values="60;20;60" begin='0.2s' dur="1s" repeatCount="indefinite" />
                                                <animate attributeType="CSS" attributeName="y" values="0;20;0" begin='0.2s' dur="1s" repeatCount="indefinite" /></rect>

                                                <rect x="40" y="0" width="10" height="60" rx="6"><animate attributeType="CSS" attributeName="height" values="60;20;60" begin='0.4s' dur="1s" repeatCount="indefinite" />
                                                <animate attributeType="CSS" attributeName="y" values="0;20;0" begin='0.4s' dur="1s" repeatCount="indefinite" /></rect>

                                                <rect x="60" y="0" width="10" height="60" rx="6"><animate attributeType="CSS" attributeName="height" values="60;20;60" begin='0.6s' dur="1s" repeatCount="indefinite" />
                                                <animate attributeType="CSS" attributeName="y" values="0;20;0" begin='0.6s' dur="1s" repeatCount="indefinite" /></rect>

                                                <rect x="80" y="0" width="10" height="60" rx="6"><animate attributeType="CSS" attributeName="height" values="60;20;60" begin='0.8s' dur="1s" repeatCount="indefinite" />
                                                <animate attributeType="CSS" attributeName="y" values="0;20;0" begin='0.8s' dur="1s" repeatCount="indefinite" /></rect>
                                                </svg>
                                            </>
                                            :
                                            <>Send Message<i className="fa-solid fa-paper-plane"></i></>
                                        }
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </ContactWrapper>
        </>
    );
}


export default ContactPage;