import { useEffect, useRef, useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { BookingListWrapper } from "../Styles/Booking-Style";
import TicketDetails from "../Modals/TicketDetailsModal";
import CancelConfirmationModal from "../Modals/CancelConfirmationModal";
import { DataContext } from "../Layouts/Data-Context";



function BookingList() {

    const [bookingId, setBookingId] = useState('');
    const [currTicket, setCurrTicket] = useState(false);
    const [canTicket, setCanTicket] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [cancelModal, setCancelModal] = useState(false);
    const [reload, setReload] = useState(false);
    const ticketDetailsRef = useRef(null);
    const cancelModalRef = useRef(null);


    useEffect(() => {
        function handleClickOutside(event) {
            if (ticketDetailsRef.current && !ticketDetailsRef.current.contains(event.target) && (!cancelModalRef.current || !cancelModalRef.current.contains(event.target))) {
                setShowDetails(false); // Close the modal when clicking outside
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setShowDetails]);

    return(
        <>
            <BookingListWrapper>
                <div className="page_inner">
                    <div className="booking_list_page_sec">
                        <div className="page_heading">
                            <h2>
                                <span>All</span>
                                <span>Bookings</span>
                                <svg id="sw-js-blob-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                    <defs>
                                        <linearGradient id="sw-gradient" x1="0" x2="1" y1="1" y2="0">
                                            <stop id="stop1" stopColor="#FC6736" offset="0%"></stop>
                                            <stop id="stop2" stopColor="rgba(251, 168, 31, 1)" offset="100%"></stop>
                                        </linearGradient>
                                    </defs>
                                    <path fill="url(#sw-gradient)" d="M9.9,-15C16.8,-13.2,28.9,-18.5,30.4,-17.1C31.8,-15.8,22.6,-7.9,17.2,-3.1C11.8,1.6,10.2,3.3,9.6,6.1C8.9,8.9,9.3,12.8,7.9,15.5C6.5,18.2,3.2,19.5,-0.2,20C-3.7,20.4,-7.4,19.8,-9.3,17.4C-11.2,15,-11.3,10.8,-13.9,7.6C-16.5,4.4,-21.6,2.2,-22,-0.2C-22.4,-2.6,-18.1,-5.3,-15.1,-7.8C-12.1,-10.4,-10.5,-12.8,-8.2,-17.2C-5.9,-21.5,-2.9,-27.8,-0.7,-26.6C1.5,-25.4,3.1,-16.7,9.9,-15Z" width="100%" height="100%" transform="translate(15 50)" strokeWidth="0"></path>
                                </svg>
                            </h2>
                        </div>
                        <div className="list_sec">
                            <div className="sec_left">
                                <div className="left_inner">
                                    <NavLink to="upcoming-bookings">Upcoming Bookings</NavLink>
                                    <NavLink to="previous-bookings">Previous Bookings</NavLink>
                                    <NavLink to="cancelled-bookings">Cancelled Bookings</NavLink>
                                </div>
                            </div>
                            <div className="sec_right">
                                <DataContext.Provider value={{ setBookingId, setCurrTicket, setCanTicket, setShowDetails, reload, setReload }}>
                                    <Outlet/>
                                </DataContext.Provider>
                            </div>
                        </div>
                    </div>
                </div>
            </BookingListWrapper>

            <div ref={ticketDetailsRef}>
                <TicketDetails bookingId={bookingId} currTicket={currTicket} canTicket={canTicket} showDetails={showDetails} setCancelModal={setCancelModal} />
            </div>
            <div ref={cancelModalRef}>
                <CancelConfirmationModal bookingId={bookingId} cancelModal={cancelModal} setCancelModal={setCancelModal} setShowDetails={setShowDetails} setReload={setReload} />
            </div>
        </>
    );
}


export default BookingList;