import { useEffect, useRef, useState } from "react";
import { ChangePasswordModalBox } from "../Styles/Modal-Style";
import axios from "axios";
import { Change_Password_URL, User_Details_URL } from "../API/Api";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify';


function PasswordChange({passwordChangeModal, setPasswordChangeModal, otpEmail}) {

    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [userDetails, setUserDetails] = useState([]);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const modalBoxRef = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalBoxRef.current && !modalBoxRef.current.contains(event.target)) {
                setPasswordChangeModal(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setPasswordChangeModal]);

    useEffect(() => {
        if (newPassword && confirmPassword) {
            if (newPassword !== confirmPassword) {
                setErrorMessage('Passwords do not match');
                setIsSubmitDisabled(true);
            } else {
                setErrorMessage('');
                setIsSubmitDisabled(false);
            }
        } else {
            setErrorMessage('');
            setIsSubmitDisabled(true);
        }
    }, [newPassword, confirmPassword]);

    function modalClose() {
        setPasswordChangeModal(false);
    }

    const handlePasswordChange = () => {

        const inputs = {
            email: otpEmail,
            newPassword: newPassword
        };

        axios.post(Change_Password_URL, inputs, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res => {
            // console.log(res);
            if(res.data.status == "200"){
                toast.success('Password has been changed.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                setPasswordChangeModal(false);
            } else if(res.data.status == "202"){
                toast.warn('Enter a password different from the previous.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            }
        })
        .catch(err => {
            // console.log(err);
            toast.error('Something Went Wrong !!!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        })
        
    };

    return(
        <>
            <ChangePasswordModalBox className={passwordChangeModal ? 'active' : ''}>
                <div ref={modalBoxRef} className={`modal_box ${passwordChangeModal ? 'active' : ''}`}>
                    <div className="box_inner">
                        <div className="icon">
                            <img src="/images/change-password.png" alt="icon" />
                        </div>
                        <div className="form_sec">
                            <div className="form_box">
                                <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
                                <span>New Password</span>
                                <a><i className="fa-solid fa-eye-slash"></i></a>
                            </div>
                            <div className="form_box">
                                <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
                                <span>Confirm Password</span>
                                <a><i className="fa-solid fa-eye-slash"></i></a>
                            </div>
                            {errorMessage && (
                                <div className="error_message"><p>{errorMessage}</p></div>
                            )}
                            <div className="btn_sec">
                                <button onClick={modalClose}>Cancel</button>
                                <button onClick={handlePasswordChange}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ChangePasswordModalBox>
        </>
    );
}


export default PasswordChange;