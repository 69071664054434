import { LoaderWrapper } from "../Styles/Loader-Style";



function Loader({loading}) {
    return(
        <>
            <LoaderWrapper className={loading ? 'active' : ''}>
                <div class="push_pop">
                    <span></span>
                    <span></span>
                </div>
                <div className="text">
                    <h4>Sending OTP, Please wait</h4>
                    <svg version="1.1" id="L5" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
                        viewBox="0 0 100 100" enable-background="new 0 0 0 0" >
                        <circle fill="#000" stroke="none" cx="0" cy="50" r="8">
                            <animateTransform 
                            attributeName="transform" 
                            dur="1s" 
                            type="translate" 
                            values="0 15 ; 0 -15; 0 15" 
                            repeatCount="indefinite" 
                            begin="0.1"/>
                        </circle>
                        <circle fill="#000" stroke="none" cx="30" cy="50" r="8">
                            <animateTransform 
                            attributeName="transform" 
                            dur="1s" 
                            type="translate" 
                            values="0 10 ; 0 -10; 0 10" 
                            repeatCount="indefinite" 
                            begin="0.2"/>
                        </circle>
                        <circle fill="#000" stroke="none" cx="60" cy="50" r="8">
                            <animateTransform 
                            attributeName="transform" 
                            dur="1s" 
                            type="translate" 
                            values="0 5 ; 0 -5; 0 5" 
                            repeatCount="indefinite" 
                            begin="0.3"/>
                        </circle>
                    </svg>
                </div>
            </LoaderWrapper>
        </>
    );
}


export default Loader;