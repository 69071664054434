import { useEffect, useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import Loader from "../Components/Loader";
import Navbar from "../Components/Nav";
import Footer from "../Components/Footer";
import Sidebar from "../Components/Sidebar";
import ModalSignup from "../Modals/SignupModal";
import ModalSignin from "../Modals/SigninModal";
import ModalCity from "../Modals/CityModal";
import ModalTermsCoditions from "../Modals/TermsConditionsModal";
import ModalPrivacyPolicy from "../Modals/PrivacyPolicyModal";
import ModalRefundPolicy from "../Modals/RefundPolicyModal";
import MailSelectModal from "../Modals/MailSelectModal";
import PasswordChangeOTPVerification from "../Modals/ChangePasswordOTPVerificationModal";
import PasswordChange from "../Modals/PasswordChangeModal";
import ProfileUpdate from "../Modals/ProfileUpdateModal";
import { DataContext } from "./Data-Context";



function PageLayout() {

    const [loading, setLoading] = useState(false);
    const [showProfile, setShowProfile] = useState(false);
    const [signinModal, setSigninModal] = useState(false);
    const [signupModal, setSignupModal] = useState(false);
    const [termsModal, setTermsModal] = useState(false);
    const [privacyModal, setPrivacyModal] = useState(false);
    const [refundModal, setRefundModal] = useState(false);
    const [otpEmail, setOtpEmail] = useState('');
    const [emailSelectModal, setEmailSelectModal] = useState(false);
    const [passwordOtpModal, setPasswordOtpModal] = useState(false);
    const [passwordChangeModal, setPasswordChangeModal] = useState(false);
    const [profileModalShow, setProfileModalShow] = useState(false);
    const [profileUpdateModal, setProfileUpdateModal] = useState(false);
    const [userImage, setUserImage] = useState('');
    const [cityModalShow, setCityModalShow] = useState(true);
    const [selectedCity, setSelectedCity] = useState('Location');
    const sidebarRef = useRef(null); 

    useEffect(() => {
        if(selectedCity == "Location") {
            setCityModalShow(true);
        } else {
            setCityModalShow(false);
        }
    }, [selectedCity]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (
                sidebarRef.current &&
                !sidebarRef.current.contains(event.target) &&
                !event.target.closest(".nav_profile")
            ) {
                setProfileModalShow(false); // Close Sidebar
            }
        }
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [profileModalShow]);


    function handleSigninModal() {
        setSigninModal(!signinModal);
    }

    function handleSignupModal() {
        setSignupModal(!signupModal);
    }

    const handleProfileSidebar = () => {
        setProfileModalShow(!profileModalShow);
    };

    return(
        <>
            <Loader loading={loading} />
            <Navbar showProfile={showProfile} setShowProfile={setShowProfile} handleSigninModal={handleSigninModal} handleProfileSidebar={handleProfileSidebar} userImage={userImage} setUserImage={setUserImage} setProfileModalShow={setProfileModalShow} selectedCity={selectedCity} setSelectedCity={setSelectedCity} setCityModalShow={setCityModalShow} />
            <DataContext.Provider value={{selectedCity, setRefundModal}}>
                <Outlet />
            </DataContext.Provider>
            <Footer termsModal={termsModal} setTermsModal={setTermsModal} privacyModal={privacyModal} setPrivacyModal={setPrivacyModal} refundModal={refundModal} setRefundModal={setRefundModal} />
            <div ref={sidebarRef}>
                <Sidebar profileModalShow={profileModalShow} setProfileModalShow={setProfileModalShow} userImage={userImage} setUserImage={setUserImage} setOtpEmail={setOtpEmail} setPasswordOtpModal={setPasswordOtpModal} setLoading={setLoading} setProfileUpdateModal={setProfileUpdateModal} />
            </div>
            <ModalCity selectedCity={selectedCity} setSelectedCity={setSelectedCity} cityModalShow={cityModalShow} setCityModalShow={setCityModalShow} />
            
            {/* password change */}
            <MailSelectModal emailSelectModal={emailSelectModal} setEmailSelectModal={setEmailSelectModal} setOtpEmail={setOtpEmail} setLoading={setLoading} setPasswordOtpModal={setPasswordOtpModal} />
            <PasswordChangeOTPVerification passwordOtpModal={passwordOtpModal} setPasswordOtpModal={setPasswordOtpModal} otpEmail={otpEmail} setPasswordChangeModal={setPasswordChangeModal} />
            <PasswordChange passwordChangeModal={passwordChangeModal} setPasswordChangeModal={setPasswordChangeModal} otpEmail={otpEmail} />

            {/* profile update */}
            <ProfileUpdate profileUpdateModal={profileUpdateModal} setProfileUpdateModal={setProfileUpdateModal} />

            {/* register */}
            <ModalSignup showModal={signupModal} handleSigninModal={handleSigninModal} handleSignupModal={handleSignupModal} setTermsModal={setTermsModal} setPrivacyModal={setPrivacyModal} />

            {/* login */}
            <ModalSignin setShowProfile={setShowProfile} showModal={signinModal} handleSigninModal={handleSigninModal} handleSignupModal={handleSignupModal} setEmailSelectModal={setEmailSelectModal} />

            {/* Terms and Conditions */}
            <ModalTermsCoditions termsModal={termsModal} setTermsModal={setTermsModal} />

            {/* Privacy Policy */}
            <ModalPrivacyPolicy privacyModal={privacyModal} setPrivacyModal={setPrivacyModal} />

            {/* Refund Policy */}
            <ModalRefundPolicy refundModal={refundModal} setRefundModal={setRefundModal} />

        </>
    );
}


export default PageLayout;