import { useEffect, useRef, useState } from "react";
import { MailSelectModalBox } from "../Styles/Modal-Style";
import axios from "axios";
import { Send_OTP_URL } from "../API/Api";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify';


function MailSelectModal({emailSelectModal, setEmailSelectModal, setOtpEmail, setLoading, setPasswordOtpModal}) {

    const [email, setEmail] = useState('');
    const modalBoxRef = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalBoxRef.current && !modalBoxRef.current.contains(event.target)) {
                setEmailSelectModal(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setEmailSelectModal]);

    function modalClose() {
        setEmailSelectModal(false);
    }

    const handleOTPSend = () => {
        setOtpEmail(email);
        setLoading(true);
        setEmailSelectModal(false);
        const inputs = {
            email: email
        }
        axios.post(Send_OTP_URL, inputs, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res => {
            console.log(res);
            if(res.data.status == "200"){
                toast.success('OTP has been sent successfully !!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                setEmail('');
                setLoading(false);
                setPasswordOtpModal(true);
            } else if(res.data.status == "202"){
                toast.error('User not found !!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                setEmail('');
                setLoading(false);
                setEmailSelectModal(true);
            }
        })
        .catch(err => {
            console.log(err);
        })
    }

    return(
        <>
            <MailSelectModalBox className={emailSelectModal ? 'active' : ''}>
                <div className={`modal_box ${emailSelectModal ? 'active' : ''}`}>
                    <div ref={modalBoxRef} className="box_inner">
                        <div className="icon">
                            <img src="/images/mail-select-icon.svg" alt="icon" />
                        </div>
                        <div className="form_sec">
                            <p>Please enter your registered email address</p>
                            <div className="form_box">
                                <input type="text" placeholder="Email address" value={email} onChange={(e) => setEmail(e.target.value)} required />
                            </div>
                        </div>
                        <div className="btn_sec">
                            <button onClick={modalClose}>Cancel</button>
                            <button onClick={handleOTPSend}>Confirm</button>
                        </div>
                    </div>
                </div>
            </MailSelectModalBox>
        </>
    );
}


export default MailSelectModal;