import React, { useContext, useEffect, useRef, useState } from "react";
import { MovieWrapper } from "../Styles/Movies-Style";
import axios from "axios";
import { Fetch_All_Shows_URL, Poster_Image_URL } from "../API/Api";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../Layouts/Data-Context";
import MovieFilter from "../Modals/MovieFilterModal";


function Movies() {

    const {selectedCity} = useContext(DataContext);

    const [movieCount, setMovieCount] = useState(false);
    const [upcomingCount, setUpcomingCount] = useState(false);

    const [movies, setMovies] = useState([]);
    const [upcomingMovies, setUpcomingMovies] = useState([]);
    const [filterModal, setFilterModal] = useState(false);
    const [reload, setReload] = useState(false);
    const navigate = useNavigate();
    const filterBoxRef = useRef();


    useEffect(() => {

        if(selectedCity) {
            axios.get(`${Fetch_All_Shows_URL}?location=${selectedCity}`)
            .then(res => {
                console.log(res);
                if(res.data.week_show_count > 0){
                    setMovieCount(true);
                    setMovies(res.data.week_shows);
                } else {
                    setMovieCount(false);
                    setMovies([]);
                }
    
                if(res.data.upcoming_show_count > 0){
                    setUpcomingCount(true);
                    setUpcomingMovies(res.data.upcoming_shows);
                } else {
                    setUpcomingCount(false);
                    setUpcomingMovies([]);
                }
            })
            .catch(err => {
                console.log(err);
            })
        } else {
            axios.get(Fetch_All_Shows_URL)
            .then(res => {
                console.log(res);
                if(res.data.week_show_count > 0){
                    setMovieCount(true);
                    setMovies(res.data.week_shows);
                } else {
                    setMovieCount(false);
                    setMovies([]);
                }
    
                if(res.data.upcoming_show_count > 0){
                    setUpcomingCount(true);
                    setUpcomingMovies(res.data.upcoming_shows);
                } else {
                    setUpcomingCount(false);
                    setUpcomingMovies([]);
                }
            })
            .catch(err => {
                console.log(err);
            })
        }
        setReload(false);

    }, [selectedCity, reload]);

    const getJoinedTypes = (types) => {
        return types.split(',').map(type => type.trim()).join(' / ');
    };

    const handleRedirect = (movieName) => {
        localStorage.setItem("Movie_name", movieName);
        navigate(`/movie?movie=${encodeURIComponent(movieName)}`);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (filterBoxRef.current && !filterBoxRef.current.contains(event.target) && !event.target.closest(".filter_toggle")) {
                setFilterModal(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setFilterModal]);


    return(
        <>
            <MovieWrapper>
                <div className="page_inner">
                    <div className="movie_box_sec">
                        <div className="box_head">
                            <h2>
                                <span>All</span>
                                <span>Movies</span>
                                <svg id="sw-js-blob-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                    <defs>
                                        <linearGradient id="sw-gradient" x1="0" x2="1" y1="1" y2="0">
                                            <stop id="stop1" stopColor="#FC6736" offset="0%"></stop>
                                            <stop id="stop2" stopColor="rgba(251, 168, 31, 1)" offset="100%"></stop>
                                        </linearGradient>
                                    </defs>
                                    <path fill="url(#sw-gradient)" d="M9.9,-15C16.8,-13.2,28.9,-18.5,30.4,-17.1C31.8,-15.8,22.6,-7.9,17.2,-3.1C11.8,1.6,10.2,3.3,9.6,6.1C8.9,8.9,9.3,12.8,7.9,15.5C6.5,18.2,3.2,19.5,-0.2,20C-3.7,20.4,-7.4,19.8,-9.3,17.4C-11.2,15,-11.3,10.8,-13.9,7.6C-16.5,4.4,-21.6,2.2,-22,-0.2C-22.4,-2.6,-18.1,-5.3,-15.1,-7.8C-12.1,-10.4,-10.5,-12.8,-8.2,-17.2C-5.9,-21.5,-2.9,-27.8,-0.7,-26.6C1.5,-25.4,3.1,-16.7,9.9,-15Z" width="100%" height="100%" transform="translate(15 50)" strokeWidth="0"></path>
                                </svg>
                            </h2>
                        </div>
                        {
                            movies.length > 0 ? 
                            <div className="box_sec">
                                {
                                    movies && movies.map((movie, index) => 
                                        <div className="movie_box" key={index}>
                                            <div className="box_inner">
                                                <div className="movie_poster">
                                                    <img src={`${Poster_Image_URL}/${movie.poster_image}`} alt="poster" />
                                                    <span>{movie.movie_name}</span>
                                                </div>
                                                <div className="movie_info">
                                                    <h3>{movie.movie_name}</h3>
                                                    <p>{getJoinedTypes(movie.types)}</p>
                                                    <a onClick={() => handleRedirect(movie.movie_name)}><span><i className="fa-solid fa-ticket"></i> Book Now</span></a>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                            :
                            <div className="no_data_message">
                                <h3>There are no movies</h3>
                            </div>
                        }
                    </div>
                    <div className="movie_box_sec">
                        <div className="box_head">
                            <h2>
                                <span>Upcoming</span>
                                <span>Movies</span>
                                <svg id="sw-js-blob-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                    <defs>
                                        <linearGradient id="sw-gradient" x1="0" x2="1" y1="1" y2="0">
                                            <stop id="stop1" stopColor="#FC6736" offset="0%"></stop>
                                            <stop id="stop2" stopColor="rgba(251, 168, 31, 1)" offset="100%"></stop>
                                        </linearGradient>
                                    </defs>
                                    <path fill="url(#sw-gradient)" d="M9.9,-15C16.8,-13.2,28.9,-18.5,30.4,-17.1C31.8,-15.8,22.6,-7.9,17.2,-3.1C11.8,1.6,10.2,3.3,9.6,6.1C8.9,8.9,9.3,12.8,7.9,15.5C6.5,18.2,3.2,19.5,-0.2,20C-3.7,20.4,-7.4,19.8,-9.3,17.4C-11.2,15,-11.3,10.8,-13.9,7.6C-16.5,4.4,-21.6,2.2,-22,-0.2C-22.4,-2.6,-18.1,-5.3,-15.1,-7.8C-12.1,-10.4,-10.5,-12.8,-8.2,-17.2C-5.9,-21.5,-2.9,-27.8,-0.7,-26.6C1.5,-25.4,3.1,-16.7,9.9,-15Z" width="100%" height="100%" transform="translate(15 50)" strokeWidth="0"></path>
                                </svg>
                            </h2>
                        </div>
                        {
                            upcomingCount ? 
                            <div className="box_sec">
                                {
                                    upcomingMovies && upcomingMovies.map((upcomingMovie, index) => 
                                        <div className="movie_box" key={index}>
                                            <div className="box_inner">
                                                <div className="movie_poster">
                                                    <img src={`${Poster_Image_URL}/${upcomingMovie.poster_image}`} alt="poster" />
                                                    <span>{upcomingMovie.name}</span>
                                                </div>
                                                <div className="movie_info">
                                                    <h3>{upcomingMovie.name}</h3>
                                                    <p>{getJoinedTypes(upcomingMovie.types)}</p>
                                                    <a onClick={() => handleRedirect(upcomingMovie.name)}><span><i className="fa-solid fa-circle-info details"></i> See Details</span></a>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                            :
                            <div className="no_data_message">
                                <h3>No upcoming movies for now</h3>
                            </div>
                        }
                    </div>
                </div>
                {
                    movies.length > 0 &&  <div className="floating_btn">
                        <a className="filter_toggle" onClick={() => setFilterModal(!filterModal)}><span><i className="fa-solid fa-filter"></i></span></a>
                    </div>
                }
            </MovieWrapper>
            <div ref={filterBoxRef}>
                <MovieFilter filterModal={filterModal} setFilterModal={setFilterModal} selectedCity={selectedCity} setMovies={setMovies} setReload={setReload} />
            </div>
        </>
    );
}


export default Movies;