import { useRef, useState } from "react";
import { SignupWrapper } from "../Styles/Modal-Style";
import axios from "axios";
import { Register_URL } from "../API/Api";
import states from '../Data/State-City.json';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify';




function ModalSignup({showModal, handleSigninModal, handleSignupModal, setTermsModal, setPrivacyModal}) {

    const [cities, setCities] = useState([]);
    const [filteredStates, setFilteredStates] = useState(states);
    const [filteredCities, setFilteredCities] = useState([]);

    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');

    const [showStates, setShowStates] = useState(false);
    const [showCities, setShowCities] = useState(false);

    const [showPassword, setShowPassword] = useState(false);


    const nameRef = useRef();
    const phoneRef = useRef();
    const emailRef = useRef();
    const passwordRef = useRef();
    const stateRef = useRef();
    const cityRef = useRef();


    const OpenTermsModal = () => {
        setTermsModal(true);
    };

    const OpenPrivacyModal = () => {
        setPrivacyModal(true);
    };

    function handleStateDropdown() {
        setShowStates(!showStates);
        setShowCities(false);
    }

    function handleCityDropdown() {
        setShowCities(!showCities);
        setShowStates(false);
    }

    function handlePassword() {
        setShowPassword(!showPassword);
    }

    function closeModal() {
        handleSignupModal();
    }

    function redirectToSignIn() {
        handleSignupModal();
        handleSigninModal();
    }

    function handleStateSearch(e) {
        const searchValue = e.target.value.toLowerCase();
        const filtered = states.filter(state => state.name.toLowerCase().includes(searchValue));
        setFilteredStates(filtered);
    }

    function handleCitySearch(e) {
        const searchValue = e.target.value.toLowerCase();
        const filtered = cities.filter(city => city.name.toLowerCase().includes(searchValue));
        setFilteredCities(filtered);
    }

    function clearForm() {
        nameRef.current.value = '';
        phoneRef.current.value = '';
        emailRef.current.value = '';
        passwordRef.current.value = '';
        stateRef.current.value = '';
        cityRef.current.value = '';
        setSelectedState('');
        setSelectedCity('');
        setFilteredStates(states);
        setFilteredCities([]);
    }

    function handleSubmit(e) {
        e.preventDefault();
        const userData = {
            name: nameRef.current.value,
            phone: phoneRef.current.value,
            email: emailRef.current.value,
            password: passwordRef.current.value,
            state: stateRef.current.value,
            city: cityRef.current.value,
        }

        axios.post(Register_URL, userData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res => {
            console.log(res);

            if(res.data.status == "200"){
                toast.success('Registration Successful !!!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                clearForm();
                handleSignupModal();
                handleSigninModal();
            } 
        })
        .catch(err => {
            console.log(err);

            if(err.response.data.status == "400"){
                toast.warn('This user is already exists !!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            } else if (err.response.data.status == "500"){
                toast.error('Internal Server Error', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            }
            clearForm();
        })
    }

    return(
        <>
            <SignupWrapper className={showModal ? 'active' : ''}>
                <div className={`signup_box ${showModal ? 'active' : ''}`}>
                    <div className="close">
                        <a onClick={closeModal}><i className="fa-solid fa-xmark"></i></a>
                    </div>
                    <div className="box_left">
                        <img src="images/Signup-Icon.svg" alt="Icon" />
                    </div>
                    <div className="box_right">
                        <form onSubmit={handleSubmit}>
                            <h3>!! Join Us Today !!</h3>
                            <div className="form_sec">
                                <div className="input_box">
                                    <input type="text" name="name" ref={nameRef} required />
                                    <span>Name</span>
                                </div>
                                <div className="input_box">
                                    <input type="text" name="phone" ref={phoneRef} required />
                                    <span>Mobile No.</span>
                                </div>
                                <div className="input_box">
                                    <input type="text" name="email" ref={emailRef} required />
                                    <span>Email</span>
                                </div>
                                <div className="input_box">
                                    <input type={showPassword ? 'text' : 'password'} name="password" ref={passwordRef} required />
                                    <span>Password</span>
                                    <a onClick={handlePassword}><i className={`fa-solid ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`}></i></a>
                                </div>
                                <div className="select_box">
                                    <input type="text" name="state" id="state" ref={stateRef} required />
                                    <div className="dropdown_btn" onClick={handleStateDropdown}>
                                        <p>{selectedState}</p>
                                        <span>State</span>
                                        <i className={`fa-solid fa-angle-down ${showStates? 'active':''}`}></i>
                                    </div>
                                    <div className={`dropdown ${showStates? 'active':''}`}>
                                        <div className="search_sec">
                                            <div className="sec_inner">
                                                <input type="text" placeholder="Search..." onChange={handleStateSearch} />
                                                <i className="fa-solid fa-magnifying-glass"></i>
                                            </div>
                                        </div>
                                        <ul>
                                            {
                                                filteredStates.map(state => (
                                                    <li onClick={(e) => {
                                                        setSelectedState(state.name)
                                                        setShowStates(false)
                                                        setSelectedCity('')
                                                        document.getElementById('state').value = state.name
                                                        document.getElementById('state').click()
                                                        setCities(states.find(state => state.name === e.target.textContent).cities)
                                                        setFilteredCities(states.find(state => state.name === e.target.textContent).cities)
                                                    }}>{state.name}</li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className="select_box">
                                    <input type="text" name="city" id="city" ref={cityRef} required />
                                    <div className="dropdown_btn" onClick={handleCityDropdown}>
                                        <p>{selectedCity}</p>
                                        <span>City</span>
                                        <i className={`fa-solid fa-angle-down ${showCities? 'active':''}`}></i>
                                    </div>
                                    <div className={`dropdown ${showCities? 'active':''}`}>
                                        {selectedState && (
                                            <div className="search_sec">
                                                <div className="sec_inner">
                                                    <input type="text" placeholder="Search..." onChange={handleCitySearch} />
                                                    <i className="fa-solid fa-magnifying-glass"></i>
                                                </div>
                                            </div>
                                        )}
                                        <ul>
                                            { 
                                                filteredCities.map(city => (
                                                    <li onClick={(e) => {
                                                        setSelectedCity(city.name)
                                                        setShowCities(false)
                                                        document.getElementById('city').value = city.name
                                                        document.getElementById('city').click()
                                                    }}>{city.name}</li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className="accept_box">
                                    <input type="checkbox" id="accept" />
                                    <label htmlFor="accept"><i class="fa-solid fa-check"></i></label>
                                    <p>By clicking this, You are accepting our <a onClick={OpenTermsModal}>Terms & Conditions</a> & <a onClick={OpenPrivacyModal}>Privacy Policy</a>.</p>
                                    <div className="form_btn">
                                        <button type="submit">Sign Up</button>
                                    </div>
                                </div>
                                <div className="redirect_box">
                                    <p>Already have an account? <a onClick={redirectToSignIn}>Signin Now</a></p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </SignupWrapper>
        </>
    );
}



export default ModalSignup;